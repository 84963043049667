.body{
  min-height: 100vh;
  color: #495057;
  overflow: hidden;
  background-color: whitesmoke;
  user-select: none;
}

.navbar{
  position: relative;
  background-color: #467fcf;
  color: #fff;
  padding: 7px 10px 7px 20px;
  margin-bottom: auto;
}

.navbar-brand{
  color: #fff;
  font-weight: 500;
}
.navbar-brand:hover{
  color: aquamarine;
}

span.header-menu{
  cursor: pointer;
  margin-right: 10px;
}
span.header-menu:hover{
  color: aquamarine;
}

.toggle-icon{
  color: #fff;
  border: 1.5px solid #fff;
  padding: 3px 10px;
  border-radius: 5px;
}

.toggle-icon:hover{
  cursor: pointer;
  color: aquamarine;
  border: 1.5px solid aquamarine;
  padding: 3px 10px;
  border-radius: 5px;
}

.banner{
  margin-top: 20px;
  margin-bottom: 20px;
}
.account-form{
  padding: 18px;
  border: 2px solid #42af42;
  background-color: aliceblue;
}
.form-group{margin: 0px;}
.form-group span{font-size: small;}
.account-link{cursor: pointer;}
.account-link:hover{text-decoration: underline;}

/* .footer{
  position: absolute;
  bottom: 0;
  align-content: center;
  align-items: center;
  width: 100vh;
  background-color: #467fcf;
  z-index: 999;
} */
