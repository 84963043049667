.hide-side-menu{
    display: none;
}

.side-menu-box-full{
    position: relative;
    float: left;
    width: auto;
    max-width: 200px;
    height: auto;
    min-height: 578px;
    overflow: hidden;
    background-color: #fff;
    border-right: 2px solid grey;
}

.side-menu-box-half{
    position: relative;
    float: left;
    width: auto;
    max-width: 58px;
    height: auto;
    min-height: 578px;
    overflow: hidden;
    background-color: #fff;
    border-right: 2px solid grey;
    transition: max-width .2s ease;
}
/* .side-menu-box-half:hover{
    max-width: 200px;
} */

.menu-row{
    padding: 0 15px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
}

.menu-row:hover{
    background-color: azure;
}

.side-menu-icon{
    padding: 12px 20px;
}
.side-menu-name{
    margin-left: 2px;
    padding: 12px 0;
    white-space: nowrap;
}

.hide-menu-button{
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 5px;
    border-top: 1px solid lightgray;
}

.active-tab{
    color: royalblue;
}