/* CUSTOM DESIGN */

.room-box{
    margin: 10px 0;
    background-color: #fff;
    border: 1px solid lightgray;
    border-radius: 3px;
    height: 100%!important;
    overflow: hidden;
}
.room-name-box{
  position: relative;
  padding: 10px 12px 0 12px;
  border-bottom: 1px solid lightgray;
}
.room-content{
  position: relative;
  padding: 16px 8px;
  overflow: hidden;
}
.item-box{
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  height: 192px;
  width: 132px;
  margin: 5px;
  padding: 5px;
  position: relative;
  user-select: none;
  float: left;
  border-radius: 5px;
  line-height: normal;
  text-align: center;
  color: slateblue;
  align-items: center;
}

.fan {
  width: 34px;
  height: auto;
  position: relative;
  top: 9px;
}
.fanHolder{
  background-image: url('../../img/fanBlank.png');
  background-size: cover;
  height: 70px;
  width: 70px;
  margin-top: 5px;
}

.fan-speed-1 {
  -webkit-animation: spin .9s linear infinite;
  animation: spin .9s linear infinite;
}
.fan-speed-2 {
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}
.fan-speed-3 {
  -webkit-animation: spin .7s linear infinite;
  animation: spin .7s linear infinite;
}
.fan-speed-4 {
  -webkit-animation: spin .6s linear infinite;
  animation: spin .6s linear infinite;
}

@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}