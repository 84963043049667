.content-page{
    margin: 0px;
    padding: 20px;
    position: relative;
    overflow-x: hidden;
    /* overflow-y: scroll; */
}
.content-page-authorization{
    margin: 0px;
    padding: 0px;
    position: relative;
    overflow-x: hidden;
    /* overflow-y: scroll; */
}

.content-heading{
    padding-bottom: 10px!important;
    border-bottom: 1px solid lightgray;
    white-space: nowrap;
}

.btn-primary{
    background-color: #467fcf;
}

.btn-primary:hover{
    background-color: #2b76df!important;
}

.btn-outline-primary:hover{
    background-color: #467fcf!important;
}

.add-form{
    margin: 10px 0;
    padding: 18px;
    background-color: #fff;
    border: 1px solid lightgray;
    border-radius: 3px;
    overflow: hidden;
}
.form-group{
    margin-bottom: 12px;
}
label{
    margin-bottom: 5px;
}

.filter-box{
    position: relative;
    padding: 10px 0 0 0;
}
.filter-room{
    color: #2b76df;
    outline: none;
    border-color: #2b76df;
    border-radius: 5px;
    padding: 2px 10px 2px 2px;
}

.alert-box{
    position: relative;
}

.content-item-box{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    height: 178px;
    width: 132px;
    margin: 10px;
    padding: 5px;
    position: relative;
    float: left;
    border-radius: 5px;
    background-color: #fff;
    text-align: center;
  }
  .item-name{
    line-height: 1.2;
    margin: 5px 0;
  }